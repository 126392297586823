import axios from 'axios';
import configs from '../configs'

const authenticateApp = async (applicationId,applicationToken) => {
    /**
     * Credenciais para autenticação na aplicação
     */
    const body = {
        applicationId,
        applicationToken
    };

    try {
        const response = await axios.post(
            `${configs.novaAPI.BASE_URL}/auth/application/authorize`,
            body
        )
        return response.data.data;
    } catch(e) {
        return e;
    }
};

const getUserToken = async (username, password) => {
    let appToken;

    // Autentica a aplicação
    try {
        /**
         * Token para autenticação da aplicação
         */
        appToken = await authenticateApp(
            configs.novaAPI.applicationId,
            configs.novaAPI.applicationToken
        );
    } catch (e) {
        return e;
    }

    const headers = {
        'app-authorization': appToken
    };

    /**
     * Credenciais para realizar o login do usuário na API
     */
    const body = {
        username,
        password,
        applicationId: configs.novaAPI.applicationId
    };

    try {
        const response = await axios.post(
            `${configs.novaAPI.BASE_URL}/auth/user/login`,
            body,
            { headers }
        )
        return response.data.data;
    } catch(e) {
        return e;
    }
};

const login = async () => {
    try {
        const token = await getUserToken(configs.novaAPI.username, configs.novaAPI.password);
        return token;
    } catch (e) {
        return e;
    }
};


const upload = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/file-upload/s3`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

export default {
    authenticateApp,
    getUserToken,
    login,
    upload
}
