import axios from 'axios';
import configs from '../configs';

const get = async (token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/banners`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const getById = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .get(
            `${configs.novaAPI.BASE_URL}/site/banners/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return  e;
    }
}

const save = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .post(
            `${configs.novaAPI.BASE_URL}/site/banners`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const edit = async (data, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    try {
        const result = await axios
        .put(
            `${configs.novaAPI.BASE_URL}/site/news/${data.id}`,
            data,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

const remove = async (id, token) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    try {
        const result = await axios
        .delete(
            `${configs.novaAPI.BASE_URL}/site/banners/${id}`,
            { headers }
        )

        return result.data.data
    } catch(e) {
        return e;
    }
}

export default {
    get,
    getById,
    save,
    edit,
    remove
}
